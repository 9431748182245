import React from "react";

interface BottomBarProps {}

const BottomBar: React.FC<BottomBarProps> = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        backgroundColor: "lightgray",
      }}
    >
      <ul
        style={{
          listStyleType: "none",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <li>Extract</li>
        <li>Gallery</li>
        <li>Analysis</li>
      </ul>
    </div>
  );
};

export default BottomBar;
