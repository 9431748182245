import React from "react";
import { useMediaQuery } from "react-responsive";
import BottomBar from "./BottomBar"; // import your BottomBar component
import Navbar from "./Navbar"; // import your Navbar component

const Navigation: React.FC = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return <>{isMobile ? <BottomBar /> : <Navbar />}</>;
};

export default Navigation;
