import React from "react";
import Button from "./components/Button";
import Navigation from "./components/Navigation";
import ImageInput from "./components/ImageInput";

const App: React.FC = () => {
  const handleClick = () => {
    alert("Button clicked!");
  };

  return (
    <div>
      <Navigation />
      <h1>Welcome to My React App!</h1>
      <Button title="Click me" onClick={handleClick} />
      <ImageInput />
    </div>
  );
};

export default App;
