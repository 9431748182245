// ImageInput.tsx

import React, { ChangeEvent, useState } from "react";

const ImageInput = () => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = function () {
        setImageSrc(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        style={{ display: "none" }}
        id="upload-button"
      />
      <label
        htmlFor="upload-button"
        style={{
          cursor: "pointer",
          color: "white",
          backgroundColor: "blue",
          padding: "10px",
        }}
      >
        Upload a photo
      </label>

      <input
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleImageChange}
        style={{ display: "none" }}
        id="camera-button"
      />
      <label
        htmlFor="camera-button"
        style={{
          cursor: "pointer",
          color: "white",
          backgroundColor: "green",
          padding: "10px",
          marginLeft: "10px",
        }}
      >
        Take a photo
      </label>

      {imageSrc && (
        <img src={imageSrc} alt="uploaded" style={{ width: "100%" }} />
      )}
    </div>
  );
};

export default ImageInput;
