import React from "react";

interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = () => {
  return (
    <div style={{ width: "100%", backgroundColor: "lightgray" }}>
      <ul
        style={{
          listStyleType: "none",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <li>Extract</li>
        <li>Gallery</li>
        <li>Analysis</li>
      </ul>
    </div>
  );
};

export default Navbar;
