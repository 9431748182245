import React from "react";
import styled from "styled-components";

// Define your button styles with styled-components
const StyledButton = styled.button`
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background: #0056b3;
  }
`;

// Define the types for your props
interface ButtonProps {
  title: string;
  onClick: () => void;
}

// Create your button component
const Button: React.FC<ButtonProps> = ({ title, onClick }) => {
  return <StyledButton onClick={onClick}>{title}</StyledButton>;
};

export default Button;
